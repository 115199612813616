<template>
  <div
    class="transition-opacity duration-300"
    :class="{
      'pointer-events-none cursor-not-allowed opacity-30': pending,
    }"
  >
    <FormKit
      v-model="formData"
      type="form"
      :actions="false"
      @submit="handleSubmit"
    >
      <FormKit
        type="hidden"
        name="cartVoucherId"
        :value="voucherProduct!.cartVoucherId"
      />

      <div class="space-y-6">
        <FormKit
          type="select"
          :floating-label="true"
          :placeholder="t('label.giftCardValue')"
          :label="t('label.giftCardValue')"
          name="selectedAmount"
          :options="amountOptions"
          validation="required"
        />

        <FormKit
          type="hidden"
          name="cartVoucherId"
          :value="voucherProduct!.cartVoucherId"
        />

        <FormKit
          :label="t('label.quantity')"
          name="quantity"
          type="number"
          :min="1"
          :step="1"
          :max="10"
          :value="formData.quantity"
          :classes="{
            inner: 'w-48',
          }"
        />

        <FormKit
          v-if="voucherProduct!.voucherDesigns.length > 1"
          :label="t('label.design')"
          name="voucherDesignId"
          required
          validation="required"
          type="radio"
          :options="designOptions"
          :classes="{
            options: 'grid !grid-cols-3 !gap-4',
            decorator: '!hidden',
            wrapper:
              '!gap-0 data-[checked]:formkit-border-focus border formkit-background rounded-md',
            option:
              'hover:formkit-background-hover rounded-md focus-within:formkit-focus overflow-hidden !p-0',
          }"
        >
          <template #label="{ option }">
            <VoucherProductFormConfigureDesignOption :option />
          </template>
        </FormKit>

        <FormKit
          v-else
          type="hidden"
          name="voucherDesignId"
          :value="voucherProduct!.voucherDesigns[0].id"
        />

        <div v-if="voucherProduct?.enableCustomisation" class="space-y-2">
          <FormKit
            v-for="i in formData.quantity"
            :key="i"
            type="group"
            name="customisations"
            :label="t('dividerLabel', { index: i })"
          >
            <Divider
              v-if="formData.quantity > 1"
              :label="t('dividerLabel', { index: i })"
            />
            <VoucherProductFormConfigureCustomisation
              :key="`personalisation-${i}`"
              :index="i - 1"
              :title="`${t('title.personalisation')} ${formData.quantity > 1 ? t('dividerLabel', { index: i }) : ''}`"
              :type="VOUCHER_SHOP_CUSTOMISATION_TYPE.PERSONALISATION"
            />
            <!-- <VoucherProductFormConfigureCustomisation
              :key="`shipping-${i}`"
              :index="i - 1"
              :title="`${t('title.shipping')} ${formData.quantity > 1 ? t('dividerLabel', { index: i }) : ''}`"
              :type="VOUCHER_SHOP_CUSTOMISATION_TYPE.SHIPPING"
            /> -->
          </FormKit>
        </div>

        <div class="grid grid-cols-1 gap-2 md:grid-cols-2">
          <VoucherProductPreview
            :voucher-product-id="voucherProduct!.id"
            :voucher-design-id="formData.voucherDesignId!"
            :customisations="voucherProduct!.customisations"
            :selected-amount="formData.selectedAmount!"
            :quantity="formData.quantity"
          />

          <Button
            :text="
              formData.cartVoucherId ? t('btn.update') : t('btn.addToCart')
            "
            :disabled="!formData.selectedAmount || !formData.voucherDesignId"
            :type="BUTTON_TYPE.SUBMIT"
          />
        </div>
      </div>
    </FormKit>
  </div>
</template>

<script setup lang="ts">
import { BUTTON_TYPE } from '@base/constants/button'
import {
  VOUCHER_SHOP_PANEL,
  VOUCHER_SHOP_CUSTOMISATION_TYPE,
} from '@base/constants/voucher-shop'

const { t, n, locale } = useI18n()
const { voucherProduct, setPanel, cinema } = await useVoucherShop()
const { addToCart, pending } = await useVoucherCart({ cinema: cinema.value })

const designOptions = computed(() =>
  voucherProduct.value?.voucherDesigns.map(({ id, name, thumbnail }) => ({
    label: name,
    value: id,
    thumbnail,
  }))
)

const amountOptions = computed(() =>
  voucherProduct.value?.priceOptions.map((priceOption) => ({
    label: n(priceOption?.price, 'currency'),
    value: priceOption?.price,
  }))
)

const formData = ref({
  quantity: voucherProduct.value?.quantity ?? 1,
  selectedAmount: voucherProduct.value?.selectedAmount,
  voucherDesignId: voucherProduct.value?.voucherDesignId,
  cartVoucherId: voucherProduct.value?.cartVoucherId,
})

async function handleSubmit({
  quantity,
  selectedAmount,
  voucherDesignId,
  cartVoucherId,
}: {
  quantity: number
  selectedAmount: number
  voucherDesignId: string
  cartVoucherId: string
}) {
  await addToCart({
    quantity,
    selectedAmount,
    voucherDesignId,
    cartVoucherId,
    voucherProduct: voucherProduct.value!,
    locale: locale.value,
    onSuccess: () => {
      setPanel(VOUCHER_SHOP_PANEL.CART)
    },
  })
}

defineOptions({
  name: 'VoucherProductFormConfigure',
})
</script>

<i18n>
de:
  title:
    personalisation: "Personalisierung"
    shipping: "Versand"
  optional: "(optional)"
  dividerLabel: "Gutschein {index}"
  label:
    giftCardValue: "Gutscheinwert"
    design: "Design"
    quantity: "Anzahl"
  btn:
    addToCart: "Zum Warenkorb hinzufügen"
    update: "Aktualisieren"
es:
  title:
    personalisation: "Personalización"
    shipping: "Envío"
  optional: "(opcional)"
  dividerLabel: "Tarjeta de regalo {index}"
  label:
    giftCardValue: "Valor de la tarjeta de regalo"
    design: "Diseño"
    quantity: "Cantidad"
  btn:
    addToCart: "Añadir al carrito"
    update: "Actualizar"
</i18n>
