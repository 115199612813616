<template>
  <div>
    <FormKit
      type="checkbox"
      name="termsOfService"
      :label="t('termsOfService')"
      :help="t('help')"
    />
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n()

defineOptions({
  name: 'CartTermsOfService',
})
</script>

<i18n>
de:
  termsOfService: "Hiermit akzeptiere ich die AGB und Datenschutzbestimmungen von kinoheld."
  help: "Hinweis: Dieser Onlineshop wird von der kinoheld GmbH betrieben. Alle Daten werden sicher verschlüsselt via TLS übermittelt. Siehe Sicherheit."
es:
  termsOfService: "Acepto los términos y condiciones de uso y la política de privacidad de kinoheld."
  help: "Nota: Este sitio web se opera por kinoheld GmbH. Todos los datos se envían de forma segura a través de TLS. Ver seguridad."
</i18n>
