<template>
  <div>
    <FormKit
      type="form"
      :actions="false"
      :classes="{ form: 'flex justify-between w-full' }"
      @submit="handleSubmit"
    >
      <Button
        :text="t('btn.edit')"
        :theme="BUTTON_THEME.SECONDARY"
        class="inline-flex"
        @click="handleEdit"
      />

      <FormKit
        type="number"
        name="quantity"
        :deletable="true"
        :step="1"
        :min="1"
        :max="10"
        :delay="300"
        :value="cartVoucherProduct.quantity"
        @input="(value: number) => handleSubmit({ quantity: value })"
      />
    </FormKit>
  </div>
</template>

<script setup lang="ts">
import type { CustomisedVoucherProduct } from '@base/types/voucher-shop'
import type { CartVoucherProduct } from '#gql/default'

interface Props {
  cartVoucherProduct: CartVoucherProduct
}

const { t, locale } = useI18n()
const { cartVoucherProduct } = defineProps<Props>()

const { configure, setPanel, cinema } = await useVoucherShop()
const { addToCart, pending } = await useVoucherCart({ cinema: cinema.value })

async function handleSubmit({ quantity }: { quantity: number }) {
  if (pending.value) {
    return
  }

  await addToCart({
    quantity,
    selectedAmount: cartVoucherProduct.selectedAmount,
    voucherDesignId: cartVoucherProduct.voucherDesignId,
    cartVoucherId: cartVoucherProduct.id,
    voucherProduct:
      cartVoucherProduct.voucherProduct as CustomisedVoucherProduct,
    locale: locale.value,
  })
}

function handleEdit() {
  configure({ cartVoucher: cartVoucherProduct })
  setPanel(VOUCHER_SHOP_PANEL.CONFIGURE, {
    cartVoucherId: cartVoucherProduct.id,
  })
}

defineOptions({
  name: 'VoucherProductFormUpdateInCart',
})
</script>

<i18n>
de:
  btn:
    edit: "Bearbeiten"
es:
  btn:
    edit: "Editar"
</i18n>
