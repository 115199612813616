<template>
  <div>
    <BookingButton
      :label="t(`btn.${type}.label`)"
      :description="getDescription"
      :prefix-icon="SVG_ICON.SQUARE_EDIT_OUTLINE"
      :suffix-icon="SVG_ICON.CHEVRON_RIGHT"
      @click="openModal"
    />

    <Teleport v-if="isModalOpen" to="body">
      <div :class="cssClasses.container">
        <div :class="cssClasses.modal" class="relative space-y-4 sm:!max-w-sm">
          <div class="flex items-center justify-between">
            <div v-text="title" />
            <Button
              :theme="BUTTON_THEME.TERTIARY"
              :title="t('btn.closeThisWindow')"
              :icon="SVG_ICON.CLOSE"
              :class="cssClasses.close"
              @click="close()"
            />
          </div>

          <div class="space-y-4 py-4">
            <template
              v-if="type === VOUCHER_SHOP_CUSTOMISATION_TYPE.PERSONALISATION"
            >
              <FormKit
                v-model="formData.text"
                type="textarea"
                :floating-label="true"
                :maxlength="100"
                :char-count="true"
                name="text"
                :label="t('label.text')"
              />
            </template>

            <template
              v-else-if="type === VOUCHER_SHOP_CUSTOMISATION_TYPE.SHIPPING"
            >
              <FormKit
                v-model="formData.address"
                type="radio"
                name="address"
                :options="[
                  { label: t('label.standardAddress'), value: 'standard' },
                  { label: t('label.customAddress'), value: 'custom' },
                ]"
                :label="t('label.address')"
              />
              <template v-if="formData.address === 'custom'">
                <FormKit
                  v-model="formData.email"
                  type="email"
                  name="email"
                  :label="t('label.email')"
                  autocomplete="new-password"
                  autocorrect="off"
                  autocapitalize="off"
                  spellcheck="false"
                  inputmode="email"
                  data-1p-ignore
                  data-lpignore="true"
                  data-form-type="other"
                />
                <FormKit
                  v-model="formData.greetingMessage"
                  type="textarea"
                  name="greetingMessage"
                  :label="t('label.greetingMessage')"
                  :help="t('help.greetingMessage')"
                />
              </template>

              <FormKit
                v-model="formData.shipping"
                type="radio"
                name="shipping"
                :options="[
                  { label: t('label.standardShipping'), value: 'standard' },
                  { label: t('label.customShipping'), value: 'custom' },
                ]"
                :label="t('label.shipping')"
              />

              <FormKit
                v-if="formData.shipping === 'custom'"
                v-model="formData.shippingDate"
                type="date"
                name="shippingDate"
                :label="t('label.shippingDate')"
                :help="t('help.shippingDate')"
              />
            </template>

            <Button
              :theme="BUTTON_THEME.PRIMARY"
              :text="t('btn.save')"
              class="w-full"
              @click="save"
            />
          </div>
        </div>
        <div :class="cssClasses.backdrop" @click="close" />
      </div>
    </Teleport>
  </div>
</template>

<script setup lang="ts">
import type { VoucherShopCustomisationType } from '@base/types/voucher-shop'
import { VOUCHER_SHOP_CUSTOMISATION_TYPE } from '@base/constants/voucher-shop'
import { BUTTON_THEME } from '@base/constants/button'
import { SVG_ICON } from '@assets/constants/inline-svg'

interface Props {
  index: number
  type: VoucherShopCustomisationType
  title: string
}

const { index, type, title } = defineProps<Props>()
const { t } = useI18n()
const emit = defineEmits(['close'])

const { customise, voucherProduct } = await useVoucherShop()

const isModalOpen = ref(false)
const formData = ref({
  text: voucherProduct.value?.customisations[index]?.text || '',
  address: voucherProduct.value?.customisations[index]?.email
    ? 'custom'
    : 'standard',
  shipping: voucherProduct.value?.customisations[index]?.date
    ? 'custom'
    : 'standard',
  email: voucherProduct.value?.customisations[index]?.email || '',
  greetingMessage: voucherProduct.value?.customisations[index]?.message || '',
  shippingDate: voucherProduct.value?.customisations[index]?.date || '',
})

const getDescription = computed(() => {
  if (type === VOUCHER_SHOP_CUSTOMISATION_TYPE.PERSONALISATION) {
    return (
      formData.value.text ||
      `${t('btn.personalisation.description')} ${t('optional')}`
    )
  } else {
    return t('btn.shipping.description')
  }
})

function save() {
  if (type === VOUCHER_SHOP_CUSTOMISATION_TYPE.PERSONALISATION) {
    customise(index, { text: formData.value.text })
  } else {
    customise(index, {
      date: formData.value.shippingDate,
      message: formData.value.greetingMessage,
      email: formData.value.email,
    })
  }
  close()
}

const { close, cssClasses, open } = useModal({
  onClose: () => {
    emit('close')
    isModalOpen.value = false
  },
})

function openModal() {
  isModalOpen.value = true
  open()
}

defineOptions({
  name: 'VoucherProductFormConfigureCustomisation',
})
</script>

<i18n>
de:
  optional: "(optional)"
  btn:
    closeThisWindow: "Fenster schließen"
    save: "Speichern und fortfahren"
    personalisation:
      label: "Personalisierung"
      description: "Persönliche Nachricht hinzufügen"
    shipping:
      label: "Versand"
      description: "sofort, an die Rechnungsadresse"
  label:
    text: "Persönliche Nachricht"
    address: "Versandadresse"
    standardAddress: "An die Standardadresse"
    customAddress: "An eine andere E-Mail-Adresse"
    email: "Versand-E-Mail-Adresse"
    greetingMessage: "Grußbotschaft"
    shipping: "Zeitpunkt des Versands"
    standardShipping: "Sofort"
    customShipping: "An einem gewünschten Termin"
    shippingDate: "Termin des Versands"
  help:
    greetingMessage: "Damit der:die Empfänder:in der E-Mail diese nicht fälschlicherweise für eine Spam-Nachricht hält."
    shippingDate: "Der Versand erfolgt um 9 Uhr des gewählten Tages. Die Gutscheine werden zusätzlich direkt nach Abschluss der Bestellung an die Rechnungsadresse verschickt."
es:
  optional: "(opcional)"
  btn:
    closeThisWindow: "Cerrar ventana"
    save: "Guardar y continuar"
    personalisation:
      label: "Personalización"
      description: "Agregar mensaje personalizado"
    shipping:
      label: "Envío"
      description: "inmediato, a la dirección de facturación"
  label:
    text: "Mensaje personal"
    address: "Dirección de envío"
    standardAddress: "A la dirección estándar"
    customAddress: "A otra dirección de correo electrónico"
    email: "Dirección de correo electrónico de envío"
    greetingMessage: "Mensaje de saludo"
    shipping: "Momento del envío"
    standardShipping: "Inmediato"
    customShipping: "En una fecha deseada"
    shippingDate: "Fecha de envío"
  help:
    greetingMessage: "Para que el destinatario del correo electrónico no lo confunda con un mensaje de spam."
    shippingDate: "El envío se realiza a las 9 de la mañana del día seleccionado. Los vales también se envían directamente a la dirección de facturación después de completar el pedido."
</i18n>
