<template>
  <div>
    <BookingHeading :title="t('payment')" />
    <FormKit
      type="radio"
      name="selectedPayment"
      :options="paymentMethodOptions"
      :required="true"
      :classes="{
        option: 'formkit-border border rounded-md',
        label: 'formkit-background hover:formkit-background-hover',
        inner: 'ml-2 mt-2',
      }"
    >
      <template #label="{ option }">
        <div
          class="flex w-full cursor-pointer items-center justify-between gap-2 p-2"
        >
          <div class="text-sm font-medium" v-text="option.label" />
          <InlineSvg
            v-if="getPaymentIcon(option.value)"
            :key="option.value"
            v-bind="getPaymentIcon(option.value)"
            class="ml-auto flex-shrink-0"
          />
        </div>
      </template>
      <template #suffix="{ option, value }">
        <PaymentTypeKps
          v-if="option.value === value && option.value === 'Kps'"
        />
      </template>
    </FormKit>
  </div>
</template>

<script setup lang="ts">
const { cart } = await useCart()
const { t } = useI18n()

const { getPaymentIcon } = usePaymentIcons()
const paymentMethodOptions = computed(() =>
  cart.value?.availablePaymentMethods.map(({ name }) => ({
    label: name,
    value: name,
  }))
)

defineOptions({
  name: 'CartPaymentSelection',
})
</script>

<i18n>
de:
  payment: "Zahlung"
es:
  payment: "Pago"
</i18n>
