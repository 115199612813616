<template>
  <div class="grid grid-cols-2 gap-4 p-4">
    <div class="col-span-2">
      <FormKit
        type="text"
        name="nameOnCard"
        :floating-label="true"
        :label="t('nameOnCard')"
        :validation="['required']"
      />
    </div>
    <div class="col-span-2">
      <FormKit
        type="text"
        name="creditCardNumber"
        :floating-label="true"
        :label="t('creditCardNumber')"
        :validation="['required']"
      />
    </div>

    <div class="col-span-2" v-text="t('validUntil')" />

    <div>
      <FormKit
        type="text"
        name="expirationDate"
        :floating-label="true"
        :label="t('expirationDate')"
        :validation="['required']"
      />
    </div>

    <div>
      <FormKit
        type="text"
        name="securityCode"
        :floating-label="true"
        :label="t('securityCode')"
        :validation="['required']"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
defineOptions({
  name: 'PaymentTypeKps',
})

const { t } = useI18n()
</script>

<i18n>
de:
  nameOnCard: "Name auf der Karte"
  creditCardNumber: "Kreditkartennummer"
  expirationDate: "Ablaufdatum"
  validUntil: "Gültig bis"
  monthYear: "Monat / Jahr"
  securityCode: "CVV"
es:
  nameOnCard: "Nombre en la tarjeta"
  creditCardNumber: "Número de tarjeta de crédito"
  validUntil: "Válido hasta"
  monthYear: "Mes / Año"
  securityCode: "CVV"
</i18n>
